import Vue from "vue";

import Register from './components/register.vue';
import '../filters';

// Vue instance
new Vue({
    el: '#app',
    components: {
        'n-register': Register,
    }
});