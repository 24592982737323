<template>
  <div>
    <h1 class="auth--title">Start Write Now!</h1>

    <!-- Name -->
    <div class="form_block one_line">
      <div class="one_line--label">
        <label for="first_name" class="form_label">Name</label>
      </div>

      <div class="one_line--inputs">
        <div class="one_line--inputs--two_columns">
          <input
            v-model="form.first_name"
            type="text"
            :class="{'form_control left': true, ' error': validationErrors.first_name}"
            id="first_name"
            name="first_name"
            placeholder="first"
            required autofocus
          >
          <input
            v-model="form.last_name"
            type="text"
            :class="{'form_control right': true, ' error': validationErrors.last_name}"
            id="last_name"
            name="last_name"
            placeholder="last"
            required
          >
        </div>

        <p v-if="validationErrors.first_name" class="auth_error">{{validationErrors.first_name[0]}}</p>
        <p v-if="validationErrors.last_name" class="auth_error">{{validationErrors.last_name[0]}}</p>
      </div>
    </div>

    <!-- Email -->
    <div class="form_block one_line">
      <div class="one_line--label">
        <label class="form_label" for="email">Email Address</label>
      </div>

      <div class="one_line--inputs">
        <input
          v-model="form.email"
          :class="{'form_control': true, ' error': validationErrors.email}"
          type="email"
          id="email"
          name="email"
          required
        >

        <p v-if="validationErrors.email" class="auth_error">{{validationErrors.email[0]}}</p>
      </div>
    </div>

    <!-- Password -->
    <div class="form_block one_line">
      <div class="one_line--label">
        <label class="form_label" for="password">Password</label>
      </div>

      <div class="one_line--inputs">
        <input
          v-model="form.password"
          :class="{'form_control': true, ' error': validationErrors.password}"
          type="password"
          id="password"
          name="password"
          required
        >

        <p class="form_help_text">Must have at least 8 characters |
          <button
            class="auth--password_toggle"
            type="button"
            @click="togglePassword"
            tabindex="-1"
          >
            <span v-if="!showPassword">Show</span> <span v-if="showPassword">Hide</span> Password
          </button>
        </p>

        <p v-if="validationErrors.password" class="auth_error">{{validationErrors.password[0]}}</p>
      </div>
    </div>

    <hr class="auth--line">

    <!-- Choose Plan -->
    <div class="form_block one_line">
      <div class="one_line--label">
        <label class="form_label">Choose Plan</label>
      </div>

      <div class="one_line--inputs auth_plans">
        <button
          :class="{
            'auth_plans--button': true,
            ' current': this.currentPlan === 'monthly'
          }"
          @click="chooseMonthlyPlan"
        >$9/Month</button>
        <button
          :class="{
            'auth_plans--button annual': true,
            ' current': this.currentPlan === 'annual'
          }"
          @click="chooseAnnualPlan"
        >$65/Year (save 40%)</button>

        <p v-if="showPlanError" class="auth_error">Please choose a plan</p>
      </div>
    </div>

    <!-- Terms -->
    <div class="form_block one_line" style="margin-top: -0.5rem;">
      <div class="one_line--label"></div>

      <div class="one_line--inputs">
        <div style="margin-bottom: 0.5rem">
          <label class="form_checkbox" style="margin-left: 8px;">
            <input
              class="form_checkbox--input"
              type="checkbox"
              name="terms_of_service"
              v-model="form.terms_of_service"
              required
            >
            I agree to the <a class="form_checkbox--link" href="https://getnovelize.com/terms" target="_blank">Terms of Service</a>
          </label>

          <p v-if="validationErrors.terms_of_service" class="auth_error">{{validationErrors.terms_of_service[0]}}</p>
        </div>

        <div>
          <label class="form_checkbox" style="margin-left: 8px;">
            <input
              class="form_checkbox--input"
              type="checkbox"
              name="privacy_policy"
              v-model="form.privacy_policy"
              required
            >
            I have read the <a class="form_checkbox--link" href="https://getnovelize.com/privacy" target="_blank">Privacy Policy</a>
          </label>

          <p v-if="validationErrors.privacy_policy" class="auth_error">{{validationErrors.privacy_policy[0]}}</p>
        </div>
      </div>
    </div>

    <!-- Button -->
    <div class="form_block one_line" style="margin-top: -0.5rem; margin-bottom: 0">
      <div class="one_line--label"></div>

      <div class="one_line--inputs">
        <button v-if="creatingAccount" class="auth_button" disabled>Processing...</button>
        <button v-else class="auth_button" @click="createAccount">ENTER PAYMENT INFO</button>
      </div>
    </div>

    <!-- Note -->
    <div class="form_block one_line">
      <div class="one_line--label"></div>

      <div class="one_line--inputs">
        <p class="auth--note">Stripe handles your secure payment processing</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        showPassword: false,
        creatingAccount: false,
        validationErrors: {},
        monthlyStripePlanId: process.env.MIX_STRIPE_MONTHLY_PRICE,
        annualStripePlanId: process.env.MIX_STRIPE_ANNUAL_PRICE,
        currentPlan: null,
        showPlanError: false,
        form: {
          stripe_price_id: null,
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          terms_of_service: false,
          privacy_policy: false,
        }
      }
    },
    methods: {
      togglePassword() {
        this.showPassword = !this.showPassword;
        let passwordInput = document.getElementById('password');

        if(this.showPassword) {
          passwordInput.type = 'text';
        } else {
          passwordInput.type = 'password';
        }
      },
      chooseMonthlyPlan() {
        this.currentPlan = 'monthly'
        this.showPlanError = false
      },
      chooseAnnualPlan() {
        this.currentPlan = 'annual'
        this.showPlanError = false
      },
      createAccount() {
        if(this.currentPlan === null) {
          this.showPlanError = true
          return
        }

        if(this.currentPlan === 'monthly') {
          this.form.stripe_price_id = this.monthlyStripePlanId;
        } else if (this.currentPlan === 'annual') {
          this.form.stripe_price_id = this.annualStripePlanId;
        } else {
          this.showPlanError = true
          return
        }

        this.creatingAccount = true;

        axios.post('/register', this.form)
          .then(user => {
            return stripe.redirectToCheckout({
              sessionId: user.data.stripe_checkout_session_id,
            }).then(response => console.log('I dont think this will get called', response));
          })
          .catch(error => {
            this.creatingAccount = false;
            this.validationErrors = error.response.data.errors;
          });
      },
    }
  }
</script>
