var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "auth--title" }, [_vm._v("Start Write Now!")]),
    _vm._v(" "),
    _c("div", { staticClass: "form_block one_line" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "one_line--inputs" }, [
        _c("div", { staticClass: "one_line--inputs--two_columns" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.first_name,
                expression: "form.first_name"
              }
            ],
            class: {
              "form_control left": true,
              " error": _vm.validationErrors.first_name
            },
            attrs: {
              type: "text",
              id: "first_name",
              name: "first_name",
              placeholder: "first",
              required: "",
              autofocus: ""
            },
            domProps: { value: _vm.form.first_name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "first_name", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.last_name,
                expression: "form.last_name"
              }
            ],
            class: {
              "form_control right": true,
              " error": _vm.validationErrors.last_name
            },
            attrs: {
              type: "text",
              id: "last_name",
              name: "last_name",
              placeholder: "last",
              required: ""
            },
            domProps: { value: _vm.form.last_name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "last_name", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _vm.validationErrors.first_name
          ? _c("p", { staticClass: "auth_error" }, [
              _vm._v(_vm._s(_vm.validationErrors.first_name[0]))
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.validationErrors.last_name
          ? _c("p", { staticClass: "auth_error" }, [
              _vm._v(_vm._s(_vm.validationErrors.last_name[0]))
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form_block one_line" }, [
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "one_line--inputs" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.email,
              expression: "form.email"
            }
          ],
          class: { form_control: true, " error": _vm.validationErrors.email },
          attrs: { type: "email", id: "email", name: "email", required: "" },
          domProps: { value: _vm.form.email },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "email", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _vm.validationErrors.email
          ? _c("p", { staticClass: "auth_error" }, [
              _vm._v(_vm._s(_vm.validationErrors.email[0]))
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form_block one_line" }, [
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "one_line--inputs" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.password,
              expression: "form.password"
            }
          ],
          class: {
            form_control: true,
            " error": _vm.validationErrors.password
          },
          attrs: {
            type: "password",
            id: "password",
            name: "password",
            required: ""
          },
          domProps: { value: _vm.form.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.form, "password", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _c("p", { staticClass: "form_help_text" }, [
          _vm._v("Must have at least 8 characters |\n        "),
          _c(
            "button",
            {
              staticClass: "auth--password_toggle",
              attrs: { type: "button", tabindex: "-1" },
              on: { click: _vm.togglePassword }
            },
            [
              !_vm.showPassword ? _c("span", [_vm._v("Show")]) : _vm._e(),
              _vm._v(" "),
              _vm.showPassword ? _c("span", [_vm._v("Hide")]) : _vm._e(),
              _vm._v(" Password\n        ")
            ]
          )
        ]),
        _vm._v(" "),
        _vm.validationErrors.password
          ? _c("p", { staticClass: "auth_error" }, [
              _vm._v(_vm._s(_vm.validationErrors.password[0]))
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("hr", { staticClass: "auth--line" }),
    _vm._v(" "),
    _c("div", { staticClass: "form_block one_line" }, [
      _vm._m(3),
      _vm._v(" "),
      _c("div", { staticClass: "one_line--inputs auth_plans" }, [
        _c(
          "button",
          {
            class: {
              "auth_plans--button": true,
              " current": this.currentPlan === "monthly"
            },
            on: { click: _vm.chooseMonthlyPlan }
          },
          [_vm._v("$9/Month")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            class: {
              "auth_plans--button annual": true,
              " current": this.currentPlan === "annual"
            },
            on: { click: _vm.chooseAnnualPlan }
          },
          [_vm._v("$65/Year (save 40%)")]
        ),
        _vm._v(" "),
        _vm.showPlanError
          ? _c("p", { staticClass: "auth_error" }, [
              _vm._v("Please choose a plan")
            ])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "form_block one_line",
        staticStyle: { "margin-top": "-0.5rem" }
      },
      [
        _c("div", { staticClass: "one_line--label" }),
        _vm._v(" "),
        _c("div", { staticClass: "one_line--inputs" }, [
          _c("div", { staticStyle: { "margin-bottom": "0.5rem" } }, [
            _c(
              "label",
              {
                staticClass: "form_checkbox",
                staticStyle: { "margin-left": "8px" }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.terms_of_service,
                      expression: "form.terms_of_service"
                    }
                  ],
                  staticClass: "form_checkbox--input",
                  attrs: {
                    type: "checkbox",
                    name: "terms_of_service",
                    required: ""
                  },
                  domProps: {
                    checked: Array.isArray(_vm.form.terms_of_service)
                      ? _vm._i(_vm.form.terms_of_service, null) > -1
                      : _vm.form.terms_of_service
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.form.terms_of_service,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.form,
                              "terms_of_service",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "terms_of_service",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "terms_of_service", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n          I agree to the "),
                _c(
                  "a",
                  {
                    staticClass: "form_checkbox--link",
                    attrs: {
                      href: "https://getnovelize.com/terms",
                      target: "_blank"
                    }
                  },
                  [_vm._v("Terms of Service")]
                )
              ]
            ),
            _vm._v(" "),
            _vm.validationErrors.terms_of_service
              ? _c("p", { staticClass: "auth_error" }, [
                  _vm._v(_vm._s(_vm.validationErrors.terms_of_service[0]))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "label",
              {
                staticClass: "form_checkbox",
                staticStyle: { "margin-left": "8px" }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.privacy_policy,
                      expression: "form.privacy_policy"
                    }
                  ],
                  staticClass: "form_checkbox--input",
                  attrs: {
                    type: "checkbox",
                    name: "privacy_policy",
                    required: ""
                  },
                  domProps: {
                    checked: Array.isArray(_vm.form.privacy_policy)
                      ? _vm._i(_vm.form.privacy_policy, null) > -1
                      : _vm.form.privacy_policy
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.form.privacy_policy,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.form,
                              "privacy_policy",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "privacy_policy",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "privacy_policy", $$c)
                      }
                    }
                  }
                }),
                _vm._v("\n          I have read the "),
                _c(
                  "a",
                  {
                    staticClass: "form_checkbox--link",
                    attrs: {
                      href: "https://getnovelize.com/privacy",
                      target: "_blank"
                    }
                  },
                  [_vm._v("Privacy Policy")]
                )
              ]
            ),
            _vm._v(" "),
            _vm.validationErrors.privacy_policy
              ? _c("p", { staticClass: "auth_error" }, [
                  _vm._v(_vm._s(_vm.validationErrors.privacy_policy[0]))
                ])
              : _vm._e()
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "form_block one_line",
        staticStyle: { "margin-top": "-0.5rem", "margin-bottom": "0" }
      },
      [
        _c("div", { staticClass: "one_line--label" }),
        _vm._v(" "),
        _c("div", { staticClass: "one_line--inputs" }, [
          _vm.creatingAccount
            ? _c(
                "button",
                { staticClass: "auth_button", attrs: { disabled: "" } },
                [_vm._v("Processing...")]
              )
            : _c(
                "button",
                {
                  staticClass: "auth_button",
                  on: { click: _vm.createAccount }
                },
                [_vm._v("ENTER PAYMENT INFO")]
              )
        ])
      ]
    ),
    _vm._v(" "),
    _vm._m(4)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "one_line--label" }, [
      _c("label", { staticClass: "form_label", attrs: { for: "first_name" } }, [
        _vm._v("Name")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "one_line--label" }, [
      _c("label", { staticClass: "form_label", attrs: { for: "email" } }, [
        _vm._v("Email Address")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "one_line--label" }, [
      _c("label", { staticClass: "form_label", attrs: { for: "password" } }, [
        _vm._v("Password")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "one_line--label" }, [
      _c("label", { staticClass: "form_label" }, [_vm._v("Choose Plan")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form_block one_line" }, [
      _c("div", { staticClass: "one_line--label" }),
      _vm._v(" "),
      _c("div", { staticClass: "one_line--inputs" }, [
        _c("p", { staticClass: "auth--note" }, [
          _vm._v("Stripe handles your secure payment processing")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }