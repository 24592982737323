import Cleave from 'cleave.js';

// Call Cleave after content loaded
document.addEventListener('DOMContentLoaded', () => {

    // Social Security Number
    $('.cleave-creditCard').each(function() {
        new Cleave(this, {
            creditCard: true
        });
    });

    // Date
    $('.cleave-date').each(function() {
        new Cleave(this, {
            date: true,
            datePattern: ['d', 'm', 'Y']
        });
    });

    // Phone
    $('.cleave-phone').each(function() {
        new Cleave(this, {
            delimiters: ['-', '-'],
            blocks: [3, 3, 4],
        });
    });
});