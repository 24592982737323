import Vue from "vue";
import moment from "moment";

Vue.filter('stripHtml', function (value) {
  let div = document.createElement("div");
  div.innerHTML = value;
  return div.textContent || div.innerText || '';
});

Vue.filter('dateTime', function(value) {
  if (value) {
    return moment(String(value)).format('M/D/YYYY h:mm:ss a')
  }
})

Vue.filter('date', function(value) {
  if (value) {
    return moment(String(value)).format('MMM Do, YYYY')
  }
})

Vue.filter('time', function(value) {'s'
  if (value) {
    return moment(String(value)).format('h:mm:ss a')
  }
})
